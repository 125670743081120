import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { groupBy } from 'lodash';
import { DateTime } from 'luxon';
import { itemsClient } from '../../../api/client';
import { ItemRequestNewItem, ItemRequestUpdateItem, QueryDependencies, QueryItemCategory, QueryItemDetails } from '../../../api/schema';
import { getPeriodMapped, getSeen } from '../../../helpers/dataHelper';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import {
  ItemsLeftBehindAgg,
  ItemsLeftBehindAggType,
  ItemsRecoveredAgg,
  ItemsRecoveredAggType,
  LastFormDataType,
  ListItemActivityListType,
  ListItemActivityQuery,
  ListItemActivityType,
  ListItemsLeftBehindAggQuery,
  ProductItemsQuery,
  ProductToolsState,
  ProductToolsType,
  QueryToolDetailsType,
  ToolsType,
} from './toolsInterface';

export const listProductToolsAdapter = createEntityAdapter<ProductToolsType>({
  selectId: (product) => product.id,
});
export const toolDetailsAdapter = createEntityAdapter<QueryToolDetailsType>({
  selectId: (item) => item.id || '',
});
export const toolDependenciesAdapter = createEntityAdapter<QueryDependencies>({
  selectId: (dependency) => dependency.id || '',
});
export const listItemsLeftBehindAggAdapter = createEntityAdapter<ItemsLeftBehindAggType>({
  selectId: (product) => product.id,
});

export const listItemsRecoveredAggAdapter = createEntityAdapter<ItemsRecoveredAggType>({
  selectId: (product) => product.id,
});

export const listItemActivityAdapter = createEntityAdapter<ListItemActivityType>({
  selectId: (list) => list.id || '',
});

export const listProductTools = createAsyncThunk('tools/listProductTools', async (query: ProductItemsQuery) => {
  const argumentsKeys = [
    'ownerId',
    'ownerWarehouseId',
    'trackingWarehouseId',
    'scanningOwnerId',
    'productId',
    'manufacturerId',
    'productTypeId',
    'ownerTypeId',
    'locationVisitId',
    'trackingSiteId',
    'jobSiteId',
    'scanStatus',
    'active',
    'search',
    'sortBy',
    'offset',
    'limit',
    'options',
  ];
  return itemsClient.listItems.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [
      ownerId?: string,
      ownerWarehouseId?: string,
      trackingWarehouseId?: string,
      scanningOwnerId?: string,
      productId?: string,
      manufacturerId?: string,
      productTypeId?: string,
      ownerTypeId?: number,
      locationVisitId?: string,
      trackingSiteId?: string,
      jobSiteId?: string,
      scanStatus?: string,
      active?: string,
      search?: string,
      sortBy?: string,
      offset?: number,
      limit?: number,
      options?: Record<string, unknown>,
    ],
  );
});

export const getToolById = createAsyncThunk('tools/getToolById', async (itemId: string) => itemsClient.getItemById(itemId));
export const updateTool = createAsyncThunk('tools/updateTool', async (values: ItemRequestUpdateItem) => itemsClient.updateItem(values));
export const toolCategories = createAsyncThunk('tools/toolCategories', async () => itemsClient.itemCategories());
export const getToolDependencies = createAsyncThunk('tools/getToolDependencies', async (itemId: string) => itemsClient.getItemDependencies(itemId));
// TODO add reducers
export const createTool = createAsyncThunk('tools/createTool', async (values: ItemRequestNewItem) => itemsClient.createItem(values));
export const getItemNextSerialNumber = createAsyncThunk('tools/getItemNextSerialNumber', async () => itemsClient.getItemNextSerialNumber());

export const listItemActivity = createAsyncThunk('tools/listItemActivity', async (query: ListItemActivityQuery) => {
  const argumentsKeys = [
    'activityBegin',
    'activityEnd',
    'activityTypeIds',
    'itemId',
    'vehicleId',
    'locationVisitId',
    'siteId',
    'sortBy',
    'offset',
    'limit',
    'options',
  ];

  return itemsClient.listItemActivity.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [
      activityBegin?: string,
      activityEnd?: string,
      activityTypeIds?: string,
      itemId?: string,
      vehicleId?: string,
      locationVisitId?: string,
      siteId?: string,
      sortBy?: string,
      offset?: number,
      limit?: number,
      options?: Record<string, unknown>,
    ],
  );
});

export const listItemsLeftBehindAgg = createAsyncThunk('tools/listItemsLeftBehindAgg', async (query: ListItemsLeftBehindAggQuery) => {
  const argumentsKeys = ['sortBy', 'offset', 'limit', 'options'];
  return itemsClient.listItemsLeftBehindAgg.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [sortBy?: string, offset?: number, limit?: number, options?: Record<string, unknown>],
  );
});
export const listItemsRecoveredAgg = createAsyncThunk('tools/listItemsRecoveredAgg', async (query: ListItemsLeftBehindAggQuery) => {
  const argumentsKeys = ['sortBy', 'offset', 'limit', 'options'];
  return itemsClient.listItemsRecoveredAgg.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [sortBy?: string, offset?: number, limit?: number, options?: Record<string, unknown>],
  );
});

export const toolsSlice = createSlice({
  name: 'tools',
  initialState: {
    productTools: listProductToolsAdapter.getInitialState(),
    toolDetails: toolDetailsAdapter.getInitialState(),
    toolDependencies: toolDependenciesAdapter.getInitialState(),
    itemsLeftBehindAgg: listItemsLeftBehindAggAdapter.getInitialState(),
    listItemsRecoveredAgg: listItemsRecoveredAggAdapter.getInitialState(),
    listItemActivity: listItemActivityAdapter.getInitialState(),
  } as ProductToolsState,
  reducers: {
    removeTool: (state, action: PayloadAction<string>) => {
      toolDetailsAdapter.removeOne(state.toolDetails, action.payload);
      listProductToolsAdapter.removeOne(state.productTools, action.payload);
    },
    removeItemNextSerialNumber: (state) => {
      state.itemNextSerialNumber = undefined;
    },
    setLastFormData: (state, action: PayloadAction<LastFormDataType | undefined>) => {
      state.lastFormData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listProductTools.pending, (state: ProductToolsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), listProductTools: true };
    });
    builder.addCase(listProductTools.fulfilled, (state: ProductToolsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, listProductTools: false };
      if (action.payload.data.result) {
        listProductToolsAdapter.upsertOne(state.productTools, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(listProductTools.rejected, (state: ProductToolsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, listProductTools: false };
    });

    builder.addCase(getToolById.pending, (state: ProductToolsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getToolById: true };
    });
    builder.addCase(getToolById.fulfilled, (state: ProductToolsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getToolById: false };
      toolDetailsAdapter.upsertOne(state.toolDetails, { id: action.payload.data.result.item_id, ...action.payload.data.result });
    });
    builder.addCase(getToolById.rejected, (state: ProductToolsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getToolById: false };
    });

    builder.addCase(updateTool.pending, (state: ProductToolsState) => {
      state.error = undefined;
      state.updating = true;
    });
    builder.addCase(updateTool.fulfilled, (state: ProductToolsState, action: AnyAction) => {
      state.updating = false;
      if (action.payload.data?.result) {
        // TODO check how and update list. BUG? different responses from Details and Update
        // console.log('action.meta.arg', action.payload.data.result);
        // toolDetailsAdapter.updateOne(state.toolDetails, { id: action.payload.data.result.id, changes: action.payload.data.result });
      }
    });
    builder.addCase(updateTool.rejected, (state: ProductToolsState, action: AnyAction) => {
      state.error = action.error;
      state.updating = false;
    });

    builder.addCase(toolCategories.pending, (state: ProductToolsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), toolCategories: true };
    });
    builder.addCase(toolCategories.fulfilled, (state: ProductToolsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, toolCategories: false };
      state.toolCategories = action.payload.data.result;
    });
    builder.addCase(toolCategories.rejected, (state: ProductToolsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, toolCategories: false };
    });

    builder.addCase(getToolDependencies.pending, (state: ProductToolsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getToolDependencies: true };
    });
    builder.addCase(getToolDependencies.fulfilled, (state: ProductToolsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getToolDependencies: false };
      toolDependenciesAdapter.upsertOne(state.toolDependencies, action.payload.data.result);
    });
    builder.addCase(getToolDependencies.rejected, (state: ProductToolsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getToolDependencies: false };
    });

    builder.addCase(getItemNextSerialNumber.pending, (state: ProductToolsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getItemNextSerialNumber: true };
    });
    builder.addCase(getItemNextSerialNumber.fulfilled, (state: ProductToolsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getItemNextSerialNumber: false };
      state.itemNextSerialNumber = action.payload.data.result.toString();
    });
    builder.addCase(getItemNextSerialNumber.rejected, (state: ProductToolsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getItemNextSerialNumber: false };
    });
    builder.addCase(listItemsLeftBehindAgg.pending, (state: ProductToolsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), listItemsLeftBehindAgg: true };
    });
    builder.addCase(listItemsLeftBehindAgg.fulfilled, (state: ProductToolsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, listItemsLeftBehindAgg: false };
      if (action.payload.data.result) {
        listItemsLeftBehindAggAdapter.upsertOne(state.itemsLeftBehindAgg, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(listItemsLeftBehindAgg.rejected, (state: ProductToolsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, listItemsLeftBehindAgg: false };
    });
    builder.addCase(listItemsRecoveredAgg.pending, (state: ProductToolsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), listItemsRecoveredAgg: true };
    });
    builder.addCase(listItemsRecoveredAgg.fulfilled, (state: ProductToolsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, listItemsRecoveredAgg: false };
      if (action.payload.data.result) {
        listItemsRecoveredAggAdapter.upsertOne(state.listItemsRecoveredAgg, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(listItemsRecoveredAgg.rejected, (state: ProductToolsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, listItemsRecoveredAgg: false };
    });
    builder.addCase(listItemActivity.pending, (state: ProductToolsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), listItemActivity: true };
    });
    builder.addCase(listItemActivity.fulfilled, (state: ProductToolsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, listItemActivity: false };
      if (action.payload.data?.result) {
        listItemActivityAdapter.upsertOne(state.listItemActivity, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(listItemActivity.rejected, (state: ProductToolsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, listItemActivity: false };
    });
  },
});

export const { selectById: selectProductToolsById } = listProductToolsAdapter.getSelectors((state: RootState) => state.tools.productTools);
export const { selectById: selectToolDetailsById } = toolDetailsAdapter.getSelectors((state: RootState) => state.tools.toolDetails);
export const { selectById: selectToolDependenciesById } = toolDependenciesAdapter.getSelectors((state: RootState) => state.tools.toolDependencies);
export const { selectById: selectItemsLeftBehindAggById } = listItemsLeftBehindAggAdapter.getSelectors((state: RootState) => state.tools.itemsLeftBehindAgg);
export const { selectById: selectItemsRecoveredAggById } = listItemsRecoveredAggAdapter.getSelectors((state: RootState) => state.tools.listItemsRecoveredAgg);
export const { selectById: listItemActivityById } = listItemActivityAdapter.getSelectors((state: RootState) => state.tools.listItemActivity);

const selectListItemActivity = createSelector(
  [listItemActivityById],
  (items): ListItemActivityListType => ({
    pagination: getPagination(items),
    result: items?.result?.map((item) => ({ ...item, periodMapped: getPeriodMapped(item.first_visit, item.last_visit) })),
  }),
);
export const getListItemActivity =
  (keys: ListItemActivityQuery) =>
  (state: RootState): ListItemActivityListType =>
    selectListItemActivity(state, keysToId(keys));

const selectProductToolsByIdMapped = createSelector(
  [selectProductToolsById],
  (items): ToolsType => ({
    pagination: getPagination(items),
    result: items?.result.map((item) => ({
      ...item,
      activeMapped: item.active ? 'Active' : 'Disabled',
      identifierProductMapped: `${item.identifier}${item.identifier && item.product_name && ' - '}${item.product_name}`,
      lastSeenLocationMapped: getLastSeenLocation(item),
      // tracked_mapped: item.vehicle.location_type !== 'At Home' ? item.vehicle.item_cnt_total || 0 : 0,
      // use_mapped: item.vehicle.location_type !== 'At Home' ? item.vehicle.item_cnt_in_use || 0 : 0,
      // leftBehind_mapped: item.vehicle.location_type !== 'At Home' ? item.vehicle.item_cnt_left_behind || 0 : 0,
      ...(() => {
        if (!item.tracking_site_name && !item.payload_type_name) {
          return { lastSeenMapped: 'On Road', lastSeenType: 'payloads' };
        }
        return item.tracking_site_name
          ? { lastSeenMapped: item.tracking_site_name, lastSeenType: 'locationVisit' }
          : { lastSeenMapped: item.payload_type_name, lastSeenType: 'payloads' };
      })(),
    })),
  }),
);

const selectItemsLeftBehindAggByIdSelector = createSelector(
  [selectItemsLeftBehindAggById],
  (items): ItemsLeftBehindAgg => ({
    pagination: getPagination(items),
    result: items?.result,
  }),
);

export const getItemsLeftBehindAggById =
  (keys: ProductItemsQuery) =>
  (state: RootState): ItemsLeftBehindAgg =>
    selectItemsLeftBehindAggByIdSelector(state, keysToId(keys));

const selectItemsRecoveredAggByIdSelector = createSelector(
  [selectItemsRecoveredAggById],
  (items): ItemsRecoveredAgg => ({
    pagination: getPagination(items),
    result: items?.result,
  }),
);

export const getItemsRecoveredAggById =
  (keys: ProductItemsQuery) =>
  (state: RootState): ItemsRecoveredAgg =>
    selectItemsRecoveredAggByIdSelector(state, keysToId(keys));

export const getToolDetailsById =
  (itemId = '') =>
  (state: RootState): QueryItemDetails | undefined =>
    selectToolDetailsById(state, itemId);

const getToolCategoriesGroupsSelector = createSelector([(state: RootState) => state.tools.toolCategories], (categories?: QueryItemCategory[]) =>
  categories ? groupBy(categories, 'group_name') : undefined,
);

export const getToolDependenciesById =
  (itemId = '') =>
  (state: RootState): QueryDependencies | undefined =>
    selectToolDependenciesById(state, itemId);

export const getToolCategoriesGroups = (state: RootState): { [key: string]: QueryItemCategory[] } | undefined => getToolCategoriesGroupsSelector(state);

export const getProductToolsById =
  (keys: ProductItemsQuery) =>
  (state: RootState): ToolsType =>
    selectProductToolsByIdMapped(state, keysToId(keys));

export const productToolsFetching = (state: RootState): boolean => state.tools.fetching?.listProductTools !== false;
export const productFetching = (state: RootState): boolean => state.tools.fetching?.getToolById !== false;
export const toolCategoriesFetching = (state: RootState): boolean => state.tools.fetching?.toolCategories !== false;
export const getToolDependenciesFetching = (state: RootState): boolean => state.tools.fetching?.getToolDependencies !== false;
export const getItemsLeftBehindAggByIdFetching = (state: RootState): boolean => state.tools.fetching?.listItemsLeftBehindAgg !== false;
export const getItemsRecoveredAggByIdFetching = (state: RootState): boolean => state.tools.fetching?.listItemsRecoveredAgg !== false;
export const getListItemActivityFetching = (state: RootState): boolean => state.tools.fetching?.listItemActivity !== false;

export const getItemIdentifier = (state: RootState): string | undefined => state.tools.itemNextSerialNumber;
export const getLastFormData = (state: RootState): LastFormDataType | undefined => state.tools.lastFormData;

export const { removeTool, removeItemNextSerialNumber, setLastFormData } = toolsSlice.actions;
export default toolsSlice.reducer;

const getLastSeenLocation = (item) => {
  const seen = getSeen(item.processed_at);
  const timeToShow = item.tracking_status === 'Tracked' ? DateTime.fromISO(item.processed_at).toLocaleString(DateTime.TIME_SIMPLE) : seen;

  const trackingStatus = {
    'Left Behind': `${timeToShow}||${item.tracking_site_name}`,
    Missing: `${timeToShow}||${item.last_seen_by}`,
    'In Use': `${timeToShow}||${item.tracking_site_name}`,
    Present: `${timeToShow}||${item.last_seen_by}`,
    'Not Tracked': 'Not Tracked',
    'Never Tracked': 'Never Tracked',
    'Never Seen': 'Never Seen',
    default: `${timeToShow}||${item.last_seen_by}`,
  };

  return trackingStatus[item.tracking_status] || trackingStatus.default;
};
