import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, CardContent, CardMedia, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { imageBase, vehiclesClient } from '../../api/client';
import { CardHeaderFlex } from '../../component/cardHeaderFlex/CardHeaderFlex';
import DependenciesButton from '../../component/dependenciesButton/DependenciesButton';
import ImageZoom from '../../component/imageZoom/ImageZoom';
import { InfoPopup } from '../../component/infoPopup/InfoPopup';
import KPI from '../../component/kpi/KPI';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import ListTypography from '../../component/listTypography/ListTypography';
import { CommonMap } from '../../component/map/Common';
import Skeleton from '../../component/skeleton/Skeleton';
import TabPanel from '../../component/tabPanel/TabPanel';
import { VehicleForm } from '../../component/vehicle/VehicleForm';
import { getPeriodMapped, tagsDate } from '../../helpers/dataHelper';
import useSearchParams from '../../hooks/useSearchParams';
import { getVehicleSummary, getVehicleSummaryFetching, vehicleSummary } from '../../redux/slices/dashboard/dashboardSlice';
import { getImagesByOwnerId, listImagesByOwnerId } from '../../redux/slices/images/imagesSlice';
import { getScanEvents, setFetching } from '../../redux/slices/scanEvents/scanEventsSlice';
import { getUserAbilities, isAdminUserSelector } from '../../redux/slices/users/usersSlice';
import {
  getVehicleByUuid,
  getVehicleDependencies,
  getVehicleDependenciesById,
  getVehicleDependenciesFetching,
  getVehicleDetailsById,
  getVehicleKPIById,
  getVehicleKpis,
  removeVehicle,
  updateVehicle,
  vehicleDetailsFetching,
  vehicleKpisFetching,
} from '../../redux/slices/vehicle/vehicleSlice';
import { useDispatch } from '../../redux/store';
import { Card, CardActions, CardContentKpi, CardFlex, CardHeader, CardHeaderTabs, GridCells, MediaHolder } from '../../styled/components';
import { LocationLabel } from '../../tool/label/Location';
import { LocationVisitsShort } from '../locationVisits/LocationVisitsShort';
import { ToolUsageContainer } from '../locationVisits/ToolUsageContainer';
import { PayloadsShort } from '../payloads/PayloadsShort';
import { InTransit } from '../tools/InTransit';
import { OwnerEvents } from './OwnerEvents';
import { ProductTypeFilter } from './ProductTypeFilter';
import { WorkingEvents } from './WorkingEvents';

export const VehicleDetails: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, tab } = useParams();
  const summary = useSelector(getVehicleSummary(id || ''));
  const fetchingSummary = useSelector(getVehicleSummaryFetching);
  const detailed = useSelector(getVehicleDetailsById(id));
  const detailsFetching = useSelector(vehicleDetailsFetching);
  const imageKeys = useSelector(getImagesByOwnerId(id));
  const dependencies = useSelector(getVehicleDependenciesById(id));
  const fetchingDependencies = useSelector(getVehicleDependenciesFetching);
  const vehicleKPI = useSelector(getVehicleKPIById(id));
  const fetchingVehicleKPI = useSelector(vehicleKpisFetching);
  const [showEditVehicle, setShowEditVehicle] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const tabValue = Number(tab || 0);
  const [popupOpen, setPopupOpen] = useState('');
  const fetching = detailsFetching || fetchingSummary;
  const visitsShortQuery = useMemo(() => ({ vehicleId: id }), [id]);
  const payloadsShortQuery = useMemo(() => ({ ownerId: id }), [id]);
  const [{ productTypeId }] = useSearchParams();
  const isAdminUser = useSelector(isAdminUserSelector);
  const abilities = useSelector(getUserAbilities);
  const [, , clearParams] = useSearchParams();

  const urlPrefix = isAdminUser ? `/admin/organizations/vehicles/${id}/details` : `/company/vehicles/${id}/details`;

  useEffect(() => {
    if (id) {
      dispatch(listImagesByOwnerId(id));
      dispatch(getVehicleByUuid(id));
      dispatch(vehicleSummary(id));
      if (isAdminUser) {
        dispatch(getVehicleDependencies(id));
      }
      if (abilities.can('Container')) {
        dispatch(getVehicleKpis(id));
      }
    }
  }, [dispatch, id, isAdminUser, abilities]);

  useEffect(() => {
    if (summary?.warehouse_id) {
      dispatch(getScanEvents({ trackingWarehouseId: summary?.warehouse_id }));
    } else if (summary?.vehicle_id) {
      dispatch(setFetching({ key: 'getScanEvents', fetching: false }));
    }
  }, [dispatch, summary]);

  const handleDelete = async () => {
    setDeleting(true);

    try {
      if (dependencies?.connected_record_count && dependencies?.connected_record_count > 0) {
        const payload = {
          device_identifier: detailed?.vehicle.device_identifier,
          home_site_id: detailed?.vehicle.home_site_id,
          license_plate: detailed?.vehicle.license_plate,
          name: detailed?.vehicle.name,
          organization_id: detailed?.vehicle.organization_id,
          vehicle_id: detailed?.id,
          active: false,
        };

        await dispatch(updateVehicle(payload)).unwrap();
      } else if (detailed?.id) {
        await vehiclesClient.deleteVehicle(detailed?.id);
        dispatch(removeVehicle(detailed?.id));
      }
      navigate(isAdminUser ? `/admin/organizations/organizations/${detailed?.vehicle.organization_id}/details/4` : '/company/vehicles');
    } catch (error) {
      setDeleting(false);
      console.log(error); // eslint-disable-line
    }
  };
  const disableDelete = fetching || fetchingDependencies;

  const handleChangeTab = (event, newValue) => {
    navigate(`${urlPrefix}/${newValue}${clearParams}`);
  };

  useEffect(() => {
    if (summary?.location_type === 'At Home' && tab === undefined && !abilities.can('Container')) {
      navigate(`${urlPrefix}/`, { replace: true });
    }
  }, [urlPrefix, id, summary?.location_type, tab, navigate, abilities]);

  const tabPrefix = productTypeId ? 'Stock' : 'Tools';

  const sitePrefix = isAdminUser ? '/admin/organizations/sites' : '/company/sites';

  const details = [
    {
      label: 'Home Site',
      value: (
        <Link to={`${summary?.site_type_name === 'Job Site' ? '/customers/job-sites' : sitePrefix}/${detailed?.vehicle.home_site?.id}/details`}>
          {detailed?.vehicle.home_site?.name}
        </Link>
      ),
    },
    {
      label: 'License Plate',
      value: detailed?.vehicle.license_plate,
    },
    {
      label: 'Status',
      value: detailed?.vehicle.active ? 'Is Active' : 'Is Disabled',
    },
    {
      label: 'Created',
      value: detailed?.vehicle.created_at ? DateTime.fromISO(detailed?.vehicle.created_at).toLocaleString(DateTime.DATE_MED) : '',
    },
  ];

  return (
    <>
      {fetching && <LinearProgress />}
      <GridCells container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <CardFlex>
            <CardHeaderFlex title={<Skeleton randomWidth fetching={fetching} value={detailed?.vehicle.name} width={100} />} legend="Details" />
            <CardHeader
              action={
                <Grid container display="flex" justifyContent="flex-end">
                  {abilities.can('edit') && (
                    <Grid item xs={12} md="auto" display="flex" justifyContent="flex-end" sx={{ pb: 2 }}>
                      <Button onClick={() => setShowEditVehicle(true)} variant="contained" color="primary" disabled={fetching} style={{ minWidth: 80 }}>
                        Edit
                      </Button>
                    </Grid>
                  )}
                  {isAdminUser && (
                    <Grid item xs={12} md="auto" display="flex" justifyContent="flex-end">
                      <DependenciesButton
                        disableDelete={disableDelete}
                        dependencies={dependencies?.connected_record_count}
                        active={detailed?.vehicle.active}
                        deleting={deleting}
                        name={detailed?.vehicle.name}
                        handleDelete={handleDelete}
                      />
                    </Grid>
                  )}
                </Grid>
              }
              title={<ListTypography data={details} fetching={fetching} />}
            />
          </CardFlex>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardFlex style={{ display: 'flex', flexDirection: 'column' }}>
            <CardHeaderFlex title="Image" legend={<ImageZoom title={detailed?.vehicle?.name} label="Current" image={imageKeys?.[0]} />} />
            <MediaHolder className={clsx({ icon: imageKeys.length === 0 })} sx={{ p: 4 }}>
              {imageKeys.length > 0 ? (
                <CardMedia component="img" image={imageBase(imageKeys[0])} alt={detailed?.vehicle.name} style={{ objectFit: 'contain' }} />
              ) : (
                <ImageOutlinedIcon sx={{ fontSize: '72px', opacity: 0.1 }} />
              )}
            </MediaHolder>
          </CardFlex>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardFlex>
            <CardHeaderFlex
              title={
                <Skeleton
                  randomWidth
                  fetching={fetching}
                  value={
                    summary?.location_type === 'On Road' ? (
                      <LocationLabel location_type={summary?.location_type} site_name={summary?.site_name} />
                    ) : (
                      <Link
                        className="mr-2"
                        to={`${summary?.site_type_name === 'Job Site' ? '/customers/job-sites' : '/company/sites'}/${summary?.site_id}/details`}
                      >
                        <LocationLabel location_type={summary?.location_type} site_name={summary?.site_name} />
                      </Link>
                    )
                  }
                />
              }
              legend="Status"
            />
            <CardContentKpi>
              <div>
                <div>
                  <Skeleton
                    randomWidth
                    fetching={fetching}
                    value={summary?.location_type === 'On Road' ? tagsDate(summary?.processed_at) : getPeriodMapped(summary?.first_visit, summary?.last_visit)}
                  />
                </div>
              </div>
              <Grid container spacing={4}>
                {!abilities.can('Container') && (
                  <Grid item xs={12} md={6}>
                    {summary?.location_type === 'At Home' ? (
                      <KPI
                        title="Tool Scans"
                        display="inline-block"
                        data={[
                          {
                            amount: summary?.total_cnt_reads || 0,
                            label: 'Total',
                            color: 'black',
                            link: `${urlPrefix}/3`,
                          },
                        ]}
                        background="grey"
                        fetching={fetching}
                      />
                    ) : (
                      <KPI
                        title="Tool Usage"
                        display="inline-block"
                        data={[
                          {
                            amount: summary?.tool_cnt_total || 0,
                            label: 'Tracked',
                            color: 'black',
                            link: `${urlPrefix}/2`,
                          },
                          {
                            amount: summary?.tool_cnt_in_use || 0,
                            label: 'In Use',
                            color: summary?.tool_cnt_in_use && summary?.tool_cnt_in_use > 0 ? 'blue' : 'black',
                            link: `${urlPrefix}/0`,
                          },
                          {
                            amount: summary?.tool_cnt_left_behind || 0,
                            label: 'Left Behind',
                            color: summary?.tool_cnt_left_behind && summary?.tool_cnt_left_behind > 0 ? 'red' : 'black',
                            link: `${urlPrefix}/1`,
                          },
                        ]}
                        background={summary?.tool_cnt_left_behind && summary?.tool_cnt_left_behind > 0 ? 'pink' : 'grey'}
                        fetching={fetching}
                      />
                    )}
                  </Grid>
                )}
                {summary?.location_type !== 'At Home' && abilities.can('Stock') && (
                  <Grid item xs={12} md={6}>
                    <KPI
                      title="Stock"
                      display="inline-block"
                      data={[
                        {
                          amount: summary?.stock_cnt_total || 0,
                          label: 'Tracked',
                          color: 'black',
                          link: `${urlPrefix}/2?productTypeId=2`,
                        },
                        {
                          amount: summary?.stock_cnt_in_use || 0,
                          label: 'On Site',
                          color: summary?.stock_cnt_in_use && summary?.stock_cnt_in_use > 0 ? 'blue' : 'black',
                          link: `${urlPrefix}/0?productTypeId=2`,
                        },
                        {
                          amount: summary?.stock_cnt_installed || 0,
                          label: 'Installed',
                          color: summary?.stock_cnt_installed && summary?.stock_cnt_installed > 0 ? 'red' : 'black',
                          link: `${urlPrefix}/3?productTypeId=2`,
                        },
                      ]}
                      background={summary?.stock_cnt_installed && summary?.stock_cnt_installed > 0 ? 'pink' : 'grey'}
                      fetching={fetching}
                    />
                  </Grid>
                )}
                {abilities.can('Container') && (
                  <Grid item xs={12} md={6}>
                    <KPI
                      title="Containers"
                      display="inline-block"
                      data={[
                        {
                          amount: vehicleKPI?.activity_delivered || 0,
                          label: 'Delivered',
                          color: 'black',
                        },
                        {
                          amount: vehicleKPI?.containers_in_transit || 0,
                          label: 'On-Board',
                          color: 'black',
                        },
                        {
                          amount: vehicleKPI?.activity_recovered || 0,
                          label: 'Recovered',
                          color: 'black',
                        },
                      ]}
                      background="grey"
                      fetching={fetchingVehicleKPI}
                    />
                  </Grid>
                )}
              </Grid>
            </CardContentKpi>
            <CardActions>
              <IconButton onClick={() => setPopupOpen('status')}>
                <InfoOutlinedIcon fontSize="small" color="secondary" />
              </IconButton>
            </CardActions>
          </CardFlex>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardFlex>
            <CardHeaderFlex
              title={<Skeleton randomWidth fetching={fetching} value={summary?.site_name || <span>&nbsp;</span>} width={100} />}
              legend="Last Location"
            />
            {summary && (
              <CommonMap
                zoom={17}
                markers={[
                  {
                    key: summary?.vehicle_id || '',
                    lat: summary?.latitude || 0,
                    lng: summary?.longitude || 0,
                    label: summary?.vehicle_name || '',
                    locationType: summary?.location_type,
                  },
                ]}
                mobileView
                withTrafficFullScreen
              />
            )}
          </CardFlex>
        </Grid>
      </GridCells>

      <Card mb={6}>
        <CardHeaderTabs
          title={
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              {abilities.can('Container') ? (
                <Tabs value={tabValue} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                  <Tab label="On Board" />
                  <Tab label="Activity" />
                  <Tab label={tabValue === 2 ? 'Site Visits' : 'Visits'} />
                  <Tab label="Payloads" />
                </Tabs>
              ) : (
                <Tabs value={tabValue} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                  <Tab label={tabValue === 0 ? `${tabPrefix} In Use` : 'In Use'} />
                  <Tab label="Activity" />
                  <Tab label={tabValue === 2 ? `${tabPrefix} Tracked` : 'Tracked'} />
                  <Tab label={tabValue === 3 ? `${tabPrefix} Assigned` : 'Assigned'} />
                  <Tab label={tabValue === 4 ? 'Site Visits' : 'Visits'} />
                  <Tab label="Payloads" />
                </Tabs>
              )}
            </Box>
          }
          action={
            <IconButton onClick={() => setPopupOpen('tabs')}>
              <InfoOutlinedIcon fontSize="small" color="secondary" />
            </IconButton>
          }
          sx={{
            '& .MuiCardHeader-content': {
              width: 0,
            },
          }}
        />
        <CardContent sx={{ p: 0 }}>
          {abilities.can('Container') ? (
            <React.Fragment>
              <TabPanel value={tabValue} index={0}>
                <InTransit warehouseId={detailed?.vehicle?.warehouse_id} />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <ToolUsageContainer withExport={false} vehicleId={id} withFilters />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <LocationVisitsShort query={visitsShortQuery} />
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <PayloadsShort query={payloadsShortQuery} disableSortBy admin={isAdminUser} />
              </TabPanel>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <TabPanel value={tabValue} index={0}>
                <Box sx={{ mb: 2 }} justifyContent="flex-end" display="flex">
                  <ProductTypeFilter />
                </Box>
                <WorkingEvents trackingStatusList="2" defaultProductTypeId={productTypeId} />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <ToolUsageContainer withExport={false} vehicleId={id} withFilters />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <Box sx={{ mb: 2 }} justifyContent="flex-end" display="flex">
                  <ProductTypeFilter />
                </Box>
                <WorkingEvents defaultProductTypeId={productTypeId} />
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <Box sx={{ mb: 2 }} justifyContent="flex-end" display="flex">
                  <ProductTypeFilter />
                </Box>
                <OwnerEvents withAssignedTo={false} />
              </TabPanel>
              <TabPanel value={tabValue} index={4}>
                <LocationVisitsShort query={visitsShortQuery} />
              </TabPanel>
              <TabPanel value={tabValue} index={5}>
                <PayloadsShort query={payloadsShortQuery} disableSortBy admin={isAdminUser} />
              </TabPanel>
            </React.Fragment>
          )}
        </CardContent>
      </Card>
      <InfoPopup
        title="Current Status"
        subtitle="Displays the current status of a vehicle along with it’s tool usage."
        onClose={() => setPopupOpen('')}
        open={popupOpen === 'status'}
      >
        <CardFlex>
          <CardHeader
            sx={{ pb: 0 }}
            className="info"
            action={<strong>(Status)</strong>}
            title={
              <>
                <Typography variant="h4" component="div" sx={{ mb: 2 }} noWrap>
                  <a href="#">@ Job Site #2342</a>
                </Typography>
                <Typography variant="body1" component="div">
                  9:38 AM to 11:43 AM (now)
                </Typography>
              </>
            }
          />
          <CardContentKpi sx={{ pt: 2 }}>
            <KPI
              title="Tool Usage"
              display="inline-block"
              data={[
                {
                  amount: summary?.tool_cnt_total || 0,
                  label: 'Tracked',
                  color: 'black',
                },
                {
                  amount: summary?.tool_cnt_in_use || 0,
                  label: 'In Use',
                  color: 'green',
                },
                {
                  amount: summary?.tool_cnt_left_behind || 0,
                  label: 'Left Behind',
                  color: summary?.tool_cnt_left_behind && summary?.tool_cnt_left_behind > 0 ? 'red' : 'black',
                },
              ]}
              background={summary?.tool_cnt_left_behind && summary?.tool_cnt_left_behind > 0 ? 'pink' : 'grey'}
              fetching={fetching}
            />
          </CardContentKpi>
          <CardActions>
            <InfoOutlinedIcon fontSize="small" color="secondary" />
          </CardActions>
        </CardFlex>
      </InfoPopup>
      <InfoPopup title="Tab Details" subtitle="Displays details about tools and vehicle activity." onClose={() => setPopupOpen('')} open={popupOpen === 'tabs'}>
        <Card sx={{ mb: 3, p: 3 }}>
          <CardHeader title={<strong>Tools Tracked</strong>} sx={{ p: 0 }} />
          <CardContent sx={{ p: 0, pt: 2 }} style={{ paddingBottom: 0 }}>
            List of tools currently tracked by the vehicle, last seen time & location, along with who tool is assigned to.
          </CardContent>
        </Card>
        <Card sx={{ mb: 3, p: 3 }}>
          <CardHeader title={<strong>Tools Assigned</strong>} sx={{ p: 0 }} />
          <CardContent sx={{ p: 0, pt: 2 }} style={{ paddingBottom: 0 }}>
            List of tools assigned to the vehicle, last seen location & time.
          </CardContent>
        </Card>
        <Card sx={{ mb: 3, p: 3 }}>
          <CardHeader title={<strong>Site Visits</strong>} sx={{ p: 0 }} />
          <CardContent sx={{ p: 0, pt: 2 }} style={{ paddingBottom: 0 }}>
            Recent list of Sites where the vehicle used tools.
          </CardContent>
        </Card>
        <Card sx={{ p: 3 }}>
          <CardHeader title={<strong>Payloads</strong>} sx={{ p: 0 }} />
          <CardContent sx={{ p: 0, pt: 2 }} style={{ paddingBottom: 0 }}>
            Recent list of scanned payloads for the vehicle.
          </CardContent>
        </Card>
      </InfoPopup>
      <VehicleForm open={showEditVehicle} onClose={() => setShowEditVehicle(false)} vehicleID={id} />
    </>
  );
};
