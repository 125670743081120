import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { imageBase } from '../../api/client';
import { QueryItemDetails } from '../../api/schema';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import { tagsDate } from '../../helpers/dataHelper';
import prepareParams from '../../helpers/prepareParams';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { getProductToolsById, listProductTools, productToolsFetching } from '../../redux/slices/tools/toolsSlice';
import { getUserAbilities } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';
import { LocationVisitsProps } from './interface';

export const ToolsInUse: React.FC<LocationVisitsProps> = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [params] = useSearchParams();
  const abilities = useSelector(getUserAbilities);
  const paramsToUse = useMemo(
    () =>
      Object.keys(params).reduce((partParams, key) => ({ ...partParams, [key]: prepareParams[key] ? prepareParams[key](params[key]) : params[key] }), {
        trackingSiteId: id,
        productTypeId: '1',
        scanStatus: '2,3',
      }),
    [params, id],
  );
  const locations = useSelector(getProductToolsById(paramsToUse));
  const fetching = useSelector(productToolsFetching);
  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: locations.pagination });
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  useEffect(() => {
    dispatch(
      listProductTools({
        ...paramsToUse,
        ...paginationToGet,
      }),
    );
  }, [dispatch, id, params, paginationToGet, paramsToUse]);

  const columns = useMemo(
    () => [
      ...(isSmUp
        ? [
            {
              accessor: 'image_key',
              Header: 'Image',
              Cell: ({ row }: { row: { original: QueryItemDetails } }) => (
                <Link to={`/tools/items/${row.original.item_id}/details`}>
                  {row.original.image_key && (
                    <img src={`${imageBase(row.original.image_key)}?height=36`} key={row.original.item_id} alt={row.original.product_name} />
                  )}
                </Link>
              ),
              disableSortBy: true,
              width: 50,
              style: { paddingTop: 2, paddingBottom: 2 },
            },
          ]
        : []),

      {
        Header: 'Tool - Product',
        accessor: 'identifier',
        Cell: ({ row }: { row: { original: QueryItemDetails } }) => (
          <Link to={`/tools/items/${row.original.item_id}/details`}>
            {row.original.identifier} - {row.original.product_name}
          </Link>
        ),
      },
      ...(abilities.can('Container')
        ? [
            {
              Header: 'Tracking Vehicle',
              accessor: 'tracking_vehicle_name',
              Cell: ({ row }: { row: { original: QueryItemDetails } }) =>
                row.original.tracking_vehicle_id ? (
                  <Link to={`/company/vehicles/${row.original.tracking_vehicle_id}/details${abilities.can('Container') ? '' : '/3'}`}>
                    {row.original.tracking_vehicle_name}
                  </Link>
                ) : (
                  row.original.tracking_vehicle_id
                ),
            },
          ]
        : [
            {
              Header: 'Tracking Vehicle',
              accessor: 'payload_warehouse_name',
            },
          ]),

      {
        Header: 'Last Used',
        accessor: 'removed_at',
        Cell: ({ row }: { row: { original: QueryItemDetails } }) => (row.original.processed_at ? tagsDate(row.original.processed_at) : ''),
      },

      {
        Header: 'Status',
        accessor: 'scan_status_name',
      },
    ],
    [isSmUp, abilities],
  );

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table columns={columns} data={locations.result || []} loading={fetching} paginationControl={paginationControl} pagination={pagination} />
      </Card>
    </>
  );
};
