import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Autocomplete, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Formik } from 'formik';
import { FormikProps } from 'formik/dist/types';
import { isEmpty } from 'lodash';
import React, { ReactElement, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import toastOptions from '../../helpers/toastOptions';
import useBooleanKeys from '../../hooks/useBooleanKeys';
import { deliverItem } from '../../redux/slices/assignments/assignmentsSlice';
import { getSitesByIdFetching, getSitesOptionsById, listSite } from '../../redux/slices/sites/sitesSlice';
import { getToolById } from '../../redux/slices/tools/toolsSlice';
import { useDispatch } from '../../redux/store';
import { TextField } from '../../styled/inputs';
import { ConfirmDialog } from '../confirmDialog/ConfirmDialog';
import SiteForm from '../site/SiteForm';

interface IProp {
  onClose: (redirectURL?: string, id?: string) => void;
  open: boolean;
  itemId?: string;
  identifier?: string;
}

interface Values {
  job_site_id: string;
}

const schema = yup.object().shape({
  job_site_id: yup.string().required('Required field'),
});

export const DeliverForm = ({ onClose, open, itemId, identifier }: IProp): ReactElement => {
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<Values>>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const jobSitesOptions = useSelector(getSitesOptionsById({ siteOwner: 'customer' }));
  const fetchingJobSites = useSelector(getSitesByIdFetching);
  const [modals, toggleModal] = useBooleanKeys({ showAddSite: false, showConfirm: false });
  const [sending, setSending] = useState(false);

  useEffect(() => {
    dispatch(listSite({ siteOwner: 'customer' }));
  }, [dispatch]);

  const handleChangeAutocompleteJobSites = (event: SyntheticEvent<Element, Event>, value) => {
    formikRef.current?.setFieldValue('job_site_id', value?.id || '');
  };

  const handleClose = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>, reason?: string) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    },
    [onClose],
  );

  const handleDeliver = async () => {
    if (itemId) {
      try {
        setSending(true);
        await dispatch(deliverItem({ itemId, deliveryTarget: { site_id: formikRef?.current?.values.job_site_id } })).unwrap();
        await dispatch(getToolById(itemId));
        toast.success(
          `${identifier} was delivered to ${jobSitesOptions.find((option) => option.id === formikRef?.current?.values.job_site_id)?.label}`,
          toastOptions,
        );
        toggleModal('showConfirm');
        onClose();
      } catch (error) {
        console.log(error); // eslint-disable-line
      } finally {
        setSending(false);
      }
    }
  };

  const selectedJobSite = jobSitesOptions.find((site) => site.id === formikRef?.current?.values.job_site_id);

  const handleCloseSiteForm = useCallback(
    async (redirect?: string) => {
      if (redirect) {
        await dispatch(listSite({ siteOwner: 'customer' }));
        setTimeout(() => {
          formikRef.current?.setFieldValue('job_site_id', redirect);
        }, 500);
      }
      toggleModal('showAddSite');
    },
    [dispatch, toggleModal],
  );

  return (
    <React.Fragment>
      <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
        <Formik
          innerRef={formikRef}
          validationSchema={schema}
          onSubmit={() => {}}
          initialValues={{
            job_site_id: '',
          }}
        >
          {({ handleSubmit, values, touched, errors }) => {
            const value = jobSitesOptions.find((site) => site.id === values.job_site_id);
            return (
              <form noValidate onSubmit={handleSubmit}>
                <DialogTitle>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h3" component="div">
                      Delivery item to job site
                    </Typography>

                    <IconButton onClick={handleClose}>
                      <CloseOutlinedIcon />
                    </IconButton>
                  </Stack>
                </DialogTitle>
                <DialogContent ref={contentRef} sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 260px)' }}>
                  <Stack direction="row" justifyContent="flex-end">
                    <Box component="span">
                      <Button variant="contained" color="primary" onClick={() => toggleModal('showConfirm')} disabled={!values.job_site_id || fetchingJobSites}>
                        Deliver
                      </Button>
                    </Box>
                  </Stack>
                  <Stack direction="row" spacing={4} alignItems="center">
                    <Box flex="auto">
                      <Autocomplete
                        options={jobSitesOptions}
                        value={value ?? null}
                        loading={fetchingJobSites}
                        noOptionsText="No Job Sites"
                        disabled={fetchingJobSites || isEmpty(jobSitesOptions)}
                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                        onChange={handleChangeAutocompleteJobSites}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="job_site_id"
                            label="Job site for delivery"
                            error={Boolean(touched.job_site_id && errors.job_site_id)}
                            fullWidth
                            helperText={touched.job_site_id && errors.job_site_id}
                            variant="outlined"
                            my={2}
                          />
                        )}
                      />
                    </Box>
                    <Box>
                      <Button color="warning" variant="contained" onClick={() => toggleModal('showAddSite')}>
                        Create
                      </Button>
                    </Box>
                  </Stack>
                </DialogContent>
              </form>
            );
          }}
        </Formik>
        <ConfirmDialog
          open={modals.showConfirm}
          onClose={() => toggleModal('showConfirm')}
          title="Deliver"
          onConfirm={handleDeliver}
          confirmLabel="Deliver"
          sending={sending}
        >
          Are you sure you want to deliver {identifier} to {selectedJobSite?.label}?
        </ConfirmDialog>
      </Dialog>
      <SiteForm open={modals.showAddSite} onClose={handleCloseSiteForm} redirectTemplate="{siteId}" />
    </React.Fragment>
  );
};
