import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import useTablePagination from '../../hooks/useTablePagination';
import { QueryLocationVisitSummaryMapped } from '../../redux/slices/locationVisits/locationVisitsInterface';
import { getLocationVisits, getLocationVisitsById, getLocationVisitsFetching } from '../../redux/slices/locationVisits/locationVisitsSlice';
import { getUserAbilities } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';
import { LocationVisitsShortProps } from './interface';

const ColorValue = styled.span<{ color: string }>`
  color: ${(props) => props.theme.palette.text[props.color]};
`;

export const LocationVisitsShort: React.FC<LocationVisitsShortProps> = ({ query }: LocationVisitsShortProps) => {
  const dispatch = useDispatch();

  const locations = useSelector(getLocationVisitsById(query));
  const fetching = useSelector(getLocationVisitsFetching);
  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: locations.pagination });
  const abilities = useSelector(getUserAbilities);

  useEffect(() => {
    dispatch(getLocationVisits({ ...query, ...paginationToGet }));
  }, [dispatch, query, paginationToGet]);

  const columns = useMemo(
    () => [
      {
        Header: 'Time On Site',
        accessor: 'periodMapped',
        disableSortBy: true,
        minWidth: 200,
        Cell: ({ row }: { row: { original: QueryLocationVisitSummaryMapped & { periodMapped: string } } }) => (
          <Link to={`/activity/site-visits/${row.original.location_visit_id}/details`}>{row.original.periodMapped}</Link>
        ),
        ellipsis: true,
      },

      {
        Header: 'Customer - Location',
        accessor: 'customerNameMapped',
        Cell: ({ row }: { row: { original: QueryLocationVisitSummaryMapped } }) =>
          row.original.site_id ? (
            <Link to={`/customers/job-sites/${row.original.site_id}/details`}>{row.original.customerLocationMapped}</Link>
          ) : (
            row.original.customerLocationMapped
          ),
        ellipsis: true,
        width: '30%',
        disableSortBy: true,
      },

      {
        Header: 'Used',
        accessor: 'tools_used_cnt',
        align: 'right',
        width: 50,
        disableSortBy: true,
      },
      {
        Header: 'Left Onsite',
        accessor: 'tools_left_behind_cnt',
        Cell: ({ row }: { row: { original: QueryLocationVisitSummaryMapped } }) => (
          <ColorValue color={`${row.original.tools_left_behind_cnt && row.original.tools_left_behind_cnt > 0 ? 'red' : 'black'}`}>
            {row.original.tools_left_behind_cnt}
          </ColorValue>
        ),
        align: 'right',
        width: 50,
        disableSortBy: true,
      },
      ...(abilities.can('Stock')
        ? [
            {
              Header: 'Stock Installed',
              accessor: 'stock_installed_cnt',
              align: 'right',
              width: 50,
              disableSortBy: true,
            },
          ]
        : []),
      {
        Header: 'Recovered',
        accessor: 'tools_recovered_cnt',
        align: 'right',
        width: 50,
        disableSortBy: true,
      },
    ],
    [abilities],
  );

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table
          columns={columns}
          data={locations.result || []}
          loading={fetching}
          size="small"
          noGlobalFilter
          paginationControl={paginationControl}
          pagination={pagination}
        />
      </Card>
    </>
  );
};
