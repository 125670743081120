import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { QueryItemAggregate } from '../../api/schema';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import { tagsDate } from '../../helpers/dataHelper';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { getItemsLeftBehindAggById, getItemsLeftBehindAggByIdFetching, listItemsLeftBehindAgg } from '../../redux/slices/tools/toolsSlice';
import { useDispatch } from '../../redux/store';

type ToolsLeftBehindAggregateProps = {
  dense?: boolean;
  title?: string;
  headerAddon?: ReactElement;
  withExport?: boolean;
  defaultPageSize?: number;
};
export const ToolsLeftBehindAggregate: React.FC<ToolsLeftBehindAggregateProps> = ({
  dense,
  title,
  headerAddon,
  withExport,
  defaultPageSize,
}: ToolsLeftBehindAggregateProps) => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const fetching = useSelector(getItemsLeftBehindAggByIdFetching);
  const items = useSelector(getItemsLeftBehindAggById(params));
  const [pagination, paginationToGet, paginationControl, paginationToGetAll] = useTablePagination({
    paginationDirty: items.pagination,
    defaultLimit: defaultPageSize,
  });
  const itemsExport = useSelector(getItemsLeftBehindAggById({ ...params, ...paginationToGetAll }));

  useEffect(() => {
    dispatch(listItemsLeftBehindAgg({ ...params, ...paginationToGet }));
  }, [dispatch, params, paginationToGet]);

  const getExportData = useCallback(async () => {
    await dispatch(
      listItemsLeftBehindAgg({
        ...params,
        ...paginationToGetAll,
      }),
    );
  }, [dispatch, params, paginationToGetAll]);

  const columns = useMemo(
    () => [
      {
        Header: 'Site',
        accessor: 'site_name',
        ellipsis: true,
        width: '50%',
        minWidth: 140,
        Cell: ({ row }: { row: { original: QueryItemAggregate } }) => (
          <Link to={`/customers/job-sites/${row.original.site_id}/details`}>{row.original.site_name}</Link>
        ),
      },

      {
        Header: 'Since',
        accessor: 'first_visit',
        width: '15%',
        ellipsis: true,
        Cell: ({ row }: { row: { original: QueryItemAggregate } }) => tagsDate(row.original.first_visit, 'TIME_SIMPLE', 'DATE_MED'),
      },
      {
        Header: 'Count',
        accessor: 'tools_left_behind_cnt',
        width: '10%',
      },
    ],
    [],
  );

  return (
    <>
      {fetching && <LinearProgress />}

      <Table
        title={title}
        columns={columns}
        data={items.result || []}
        loading={fetching}
        toolbarDense="medium"
        size={dense ? 'small' : 'medium'}
        noGlobalFilter
        paginationControl={paginationControl}
        pagination={pagination}
        headerAddon={headerAddon}
        {...(withExport && { dataExport: itemsExport.result, getExportData })}
      />
    </>
  );
};
