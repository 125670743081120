import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ToolsList } from '../../component/tools/ToolsList';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { getProductToolsById, listProductTools } from '../../redux/slices/tools/toolsSlice';
import { useDispatch } from '../../redux/store';

export const InTransit: React.FC<{ warehouseId?: string }> = ({ warehouseId }: { warehouseId?: string }) => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const paramsToUse = useMemo(
    () =>
      Object.keys(params).reduce((partParams, key) => ({ ...partParams, [key]: params[key] }), {
        trackingWarehouseId: warehouseId || '',
        productTypeId: '1',
        trackingStatus: 'Present',
        active: 'true',
        scanStatus: '1,2',
      }),
    [params, warehouseId],
  );

  const productTools = useSelector(getProductToolsById(paramsToUse));

  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: productTools.pagination });

  useEffect(() => {
    dispatch(
      listProductTools({
        ...paramsToUse,
        ...paginationToGet,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGet]);

  return (
    <ToolsList items={productTools} paginationControl={paginationControl} pagination={pagination} withAssignedTo withActive withProduct withManufacturer />
  );
};
